import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { map } from 'rxjs/operators';
import { TransactionalEmailReduced } from '@interfaces/dws/transactional-email';


@Injectable({
  providedIn: 'root'
})
export class TransactionalEmailsService {
  list(wineryId: string): Observable<DWS.TransactionalEmailReduced[]> {
    return resource('crm://transactional-emails')
      .params({ wineryId })
      .get<DWS.TransactionalEmail.ReducedData[]>();
  }

  listFilterByTrigger(trigger: string | undefined, wineryId: string): Observable<DWS.TransactionalEmailReduced[]> {
    if (!trigger) return this.list(wineryId);
    return this.list(wineryId).pipe(
      map(emails => emails.filter(email => email.type.includes(trigger)))
    );
  }

  get(id: number, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/id')
      .params({ id, wineryId })
      .get<DWS.TransactionalEmail>();
  }

  saveLive(id: number, value: DWS.TransactionalEmail.NewBase, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/id')
      .params({ id, wineryId })
      .post<DWS.TransactionalEmail>(value);
  }

  saveDraft(id: number, value: DWS.TransactionalEmail.NewBase, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/draft/id')
      .params({ id, wineryId })
      .post<DWS.TransactionalEmail>(value);
  }

  restore(id: number, wineryId: string): Observable<void> {
    return resource('crm://transactional-emails/restore/id')
      .params({ id, wineryId })
      .delete();
  }

  deleteDraft(id: number, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/draft/id')
      .params({ id, wineryId })
      .delete();
  }

  sendTestEmail(email: DWS.TestTransactionalEmail): Observable<void> {
    return resource('crm://transactional-emails/send-test')
      .post<void>(email);
  }

  updateExperiences(transactionalEmailType: string, transactionalEmailId: number, experienceIds: string[]): Observable<TransactionalEmailReduced> {
    return resource('crm://transactional-email-experiences')
      .post<TransactionalEmailReduced>({ transactionalEmailType, transactionalEmailId, experienceIds });
  }

  clone(id: number, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/clone/id')
      .params({ id, wineryId })
      .post<DWS.TransactionalEmail>(id);
  }

  getById(id: number, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/id')
      .params({ id, wineryId })
      .get<DWS.TransactionalEmail>();
  }

  saveLiveById(id: number, value: DWS.TransactionalEmail.NewBase, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/id')
      .params({ id, wineryId })
      .post<DWS.TransactionalEmail>(value);
  }

  saveDraftById(id: number, value: DWS.TransactionalEmail.NewBase, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/draft/id')
      .params({ id, wineryId })
      .post<DWS.TransactionalEmail>(value);
  }

  restoreById(id: number, wineryId: string): Observable<void> {
    return resource('crm://transactional-emails/id')
      .params({ id, wineryId })
      .delete();
  }

  delete(id: number, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/delete/id')
      .params({ id, wineryId })
      .delete();
  }
}
