import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { ConditionType, Matcher } from 'interfaces/dws/matcher';
import { formatISO } from 'date-fns';
import { ExportContact } from '../../interfaces/dws/contact';
import FilterContactMatcherValue = Matcher.FilterContactMatcherValue;
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor() {
  }
  
  
  
  filterBy(filter: DWS.Contact.Filter & {
    wineryId?: string,
    includeUnsubscribed?: boolean,
    includeSubscribed?: boolean,
   isArchived?: boolean }, page: number = 0, count: number = 999999, orderBy: string = 'name', orderDir: string = 'asc'): Observable<DWS.Page<DWS.Contact>> {
    const creationStart = filter.wineryCreatedAt?.start ? moment(filter.wineryCreatedAt?.start).toDate() : undefined
    const creationEnd = filter.wineryCreatedAt?.end ? moment(filter.wineryCreatedAt?.end).toDate() : undefined
    const presenceStart = filter.presence?.start ? moment(filter.presence?.start).toDate() : undefined
    const presenceEnd = filter.presence?.end ? moment(filter.presence?.end).toDate() : undefined
    
    const queryFilters = {
      name: filter.name,
      email: filter.email,
      phone: filter.phone,
      languageId: (filter.languages || []).map(l => l.id),
      tagId: (filter.tag || []).map(t => t.id),
      segmentId: (filter.segment || []).map(s => s.id),
      typologyId: (filter.typology || []).map(t => t.id),
      acceptedMarketing: (filter.privacies || []).some(f => f.privacy == 'acceptedMarketing') || undefined,
      acceptedPrivacyTerms: (filter.privacies || []).some(f => f.privacy == 'acceptedPrivacyTerms') || undefined,
      acceptedProfiling: (filter.privacies || []).some(f => f.privacy == 'acceptedProfiling') || undefined,
      manualMarketing: (filter.privacies || []).some(f => f.privacy == 'manualMarketing') || undefined,
      customOriginId: (filter.origin || []).map(o => o.id).filter(o => typeof o === 'string'),
      originId: (filter.origin || []).map(o => o.id).map(o => typeof o !== 'string' ? o : 20),
      creationStart: creationStart ? formatISO(creationStart, { representation: 'date' }) : undefined,
      creationEnd: creationEnd ? formatISO(creationEnd, { representation: 'date' }) : undefined,
      presenceStart: presenceStart ? formatISO(presenceStart, { representation: 'date' }) : undefined,
      presenceEnd: presenceEnd ? formatISO(presenceEnd, { representation: 'date' }) : undefined,
      presenceMatch: (filter.presence as any || {}).presence,
      wineryId: filter.wineryId,
      isArchived: filter.isArchived || false
    }
    
    console.log(queryFilters);
    return resource('crm://contacts/filter-by')
        .params(
            { page, count, ...queryFilters, orderBy, orderDir }
        )
        .get<DWS.Page<DWS.Contact>>();
  }

  list(filter?: ContactsFilter, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.Contact>> {
    return resource('crm://contacts')
      .params(
        { page, count, ...filter }
      )
      .get<DWS.Page<DWS.Contact>>();
  }

  listByMatcher(filter: FilterContactMatcherValue, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.FullContact>> {
    return resource('crm://contacts/matchers')
      .params(
        { page, count }
      )
      .post<DWS.Page<DWS.FullContact>>(filter);
  }
  
  get(contactId: number, companyId: string): Observable<DWS.Contact> {
    return resource('crm://contacts/id/companyId')
      .params({ id: contactId, companyId: companyId })
      .get<DWS.Contact>();
  }

  getv2(contactId: number, companyId: string): Observable<DWS.Contact> {
    return resource('crm://contacts/v2/id/companyId')
    .params({ id: contactId, companyId: companyId })
    .get<any>()
      .pipe(
        map((response: any) => {
          const contactData = response; // Change this according to your API response structure
          const wineryId = contactData.winery?.id; // Use optional chaining to avoid errors
          contactData.wineryId = wineryId;
          return contactData as DWS.Contact; // Cast to DWS.Contact
        })
      );
  }

  getByEmail(email: string): Observable<DWS.Contact> {
    return resource('crm://contacts/email')
      .params({ email, wineryId: '' })
      .post<DWS.Contact>();
  }

  ref(contactRef: string): Observable<DWS.Contact> {
    return resource('crm://contacts/ref')
      .params({ ref: contactRef })
      .get<DWS.Contact>();
  }
  
  update(contactId: number | undefined, companyId: string, contact: DWS.Contact) {
    return resource('crm://contacts/id/companyId')
      .params({ id: contactId, companyId: companyId })
      .put({...contact, id: contactId});
  }

  updateShort(contactId: number | undefined, contact: DWS.Reservation.ReservationContactDTO) {
    return resource('crm://contacts/update-short/id')
      .params({ id: contactId })
      .put({...contact, id: contactId});
  }
  
  create(completeInput: DWS.ContactCompleteInput): Observable<any> {
    return resource('crm://contacts')
      .post(completeInput)
  }

  contactsToExport(wineryId: string, creationStart: Date | undefined, creationEnd: Date | undefined, filter: DWS.Contact.Filter & { wineryId?: string, includeUnsubscribed?: boolean, includeSubscribed?: boolean, isArchived?: boolean }): Observable<ExportContact[]> {
    const queryFilters = {
      name: filter.name,
      email: filter.email,
      phone: filter.phone,
      language: (filter.languages || []).map((l: any) => l.nameEn),
      tags: (filter.tag || []).map(t => t.id),
      segment: (filter.segment || []).map(s => s.name),
      typologyId: filter.typology || [1],
      acceptedMarketing: (filter.privacies || []).some(f => f.privacy == 'acceptedMarketing') || undefined,
      acceptedPrivacyTerms: (filter.privacies || []).some(f => f.privacy == 'acceptedPrivacyTerms') || undefined,
      acceptedProfiling: (filter.privacies || []).some(f => f.privacy == 'acceptedProfiling') || undefined,
      manualMarketing: (filter.privacies || []).some(f => f.privacy == 'manualMarketing') || undefined,
      originId: (filter.origin || []).map(o => o.id),
      presenceStart: filter.presence?.start ? formatISO(moment(filter.presence.start).toDate(), { representation: 'date' }) : undefined,
      presenceEnd: filter.presence?.end ? formatISO(moment(filter.presence.end).toDate(), { representation: 'date' }) : undefined,
      presenceMatch: (filter.presence as any || {}).presence,
      isArchived: filter.isArchived || false
    }
    
    return resource('crm://contacts/export')
        .params({
          wineryId,
          creationStart: creationStart ? formatISO(creationStart, {representation: 'date'}) : undefined,
          creationEnd: creationEnd ? formatISO(creationEnd, {representation: 'date'}) : undefined,
          ...queryFilters
        })
        .get<ExportContact[]>()
  }
}

export interface ContactsFilter {
  wineryIds: string[];
  includeUnsubscribed?: boolean | false;
  includeSubscribed?: boolean | false;
  tagId?: any[];
  audienceId?: any[];
  segmentId?: any[];
  q?: string;
  isArchived?: boolean;
  tourOperators?: boolean;
}

export interface ContactsFilterMatcher {
  wineryId: string;
  conditionType: ConditionType;
  values: ConditionType;
}